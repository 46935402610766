import React from "react";
import gsap from "gsap";
function Domains() {
  const domains = [
    {
      heading: "Technical",
      description:
        "Just as the Formula 1 universe revolves around its racing circuits, technology serves as the fundamental building block of this high-speed realm. The technical domain stands as the beating heart and soul of the Formula 1 community. Within this domain, passionate individuals and teams collaborate to engineer innovative solutions that address real-time challenges. It also offers a unique opportunity to work alongside others and specialize in specific sub-domains, much like the diverse elements that make up a Formula 1 team.",
      subDomains: ["AI/ML", "Web Dev","App Dev"],
    },
    {
      heading: "Creatives",
      description:
        "This realm is bustling with Formula One fanatics who infuse their creative flair to illuminate the high-speed track. The creative domain invites you to leave your mark in the exhilarating world of Formula 1 with your VFX and GFX skills because once you enter, there's no pit stop on this thrilling journey!",
      subDomains: ["UI/UX", "GFX", "VFX","Photography"],
    },
    {
      heading: "Corporate",
      description:
        "The Formula 1 circuit stands as the radiant heart of our racing cosmos, much like the sun in our solar system. Its gravitational influence, akin to our corporate domain, harmoniously unifies the entire motorsport universe. This synergy extends to its close collaboration with the technical and creative teams, shaping every facet of this thrilling racing world, from race strategy to the negotiation of sponsorship agreements, illuminating the path to an exhilarating journey.",
      subDomains: [
        "Content",
        "Sponsorship",
        "Event Management",
        "Public Relations",
      ],
    },
    {
      heading: "Research",
      description:
        "The research efforts in the Formula 1 domain serve as the driving force behind our pursuit of racing excellence, similar to the sun at the center of our solar system. These intellectual endeavors, much like our corporate domain, unite and propel the entire Formula 1 universe. This collaboration extends to its close partnership with technical and creative teams, shaping every aspect of our racing world, from car innovations to strategic race planning, guiding us through a fascinating journey on the Formula 1 circuit of discovery.",
     subDomains:[],
    },
  ];
  let domainsRef = React.useRef(null);
  React.useEffect(() => {
    gsap.context(() => {
      gsap.fromTo(
        ".heading",
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          scrollTrigger: {
            trigger: ".heading",
          },
        }
      );
      gsap.utils.toArray(".domain").forEach((domain) => {
        gsap.fromTo(
          domain,
          { opacity: 0, x: -100 },
          {
            opacity: 1,
            x: 0,
            duration: 0.8,
            scrollTrigger: {
              trigger: domain,
              start: "top center",
            },
          }
        );
      });
    }, domainsRef);
  }, []);

  return (
    <div className="domains" id="domains" ref={(el) => (domainsRef = el)}>
      <h1 className="heading">DOMAINS</h1>
      {domains.map((domain) => {
        return (
          <section className="domain">
            <h2>{domain.heading}</h2>
            <p>{domain.description}</p>
            <ul>
              {domain.subDomains.map((subDomain) => (
                <li>{subDomain}</li>
              ))}
            </ul>
          </section>
        );
      })}
    </div>
  );
}
export default Domains;
